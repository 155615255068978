<template>
  <div class="data-display">
    <h2 class="subtitle">Data Display</h2>
    <div class="table-container">
      <table class="data-table">
        <thead>
          <tr>
            <th>Line Item</th>
            <th>Time</th>
            <th>Low</th>
            <th>High</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" @mouseover="showHoverBox(item.id)" @mouseleave="hideHoverBox">
            <td>{{ item.line_item }}</td>
            <td>{{ item.time }}</td>
            <td>{{ item.low }}</td>
            <td>{{ item.high !== null ? item.high : '-' }}</td>
           
          </tr>
        </tbody>
      </table>
     <div class="hover-box" v-if="hoveredItem !== null" :style="{ top: mouseY + 'px', left: mouseX + 10 + 'px' }">
      <p>More data:</p>
      <p>{{ items[hoveredItem].reference }}</p>
     </div>

    </div>
    <p v-if="!hasData" class="no-data-message">No data available.</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    ticker: {
      type: String,
      default: 'M',
    },
  },
  data() {
    return {
      items: [],
      hoveredItem: null,
      mouseX: 0,
      mouseY: 0,
    };
  },
  mounted() {
    console.log('Ticker Prop:', this.ticker);
    this.fetchData();
    document.addEventListener('mousemove', this.updateMousePosition);
  },
  beforeUnmount() {
    document.removeEventListener('mousemove', this.updateMousePosition);
  },
  computed: {
    hasData() {
      return this.items.length > 0;
    },
  },
  methods: {
    fetchData() {
      axios
        .get(`http://localhost:3000/api/most_recent?company=${this.ticker}`, { headers: { 'Cache-Control': 'no-cache' } })
        .then(response => {
          console.log('Retrieved data for', this.ticker);
          this.items = response.data;
        })
        .catch(error => {
          console.error(error);
          this.items = [];
        });
    },
    showHoverBox(itemId) {
      this.hoveredItem = this.items.findIndex(item => item.id === itemId);
    },
    hideHoverBox() {
      this.hoveredItem = null;
    },
    updateMousePosition(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    },
  },
  watch: {
    ticker(newTicker) {
      console.log('Ticker Changed:', newTicker);
      this.fetchData();
    },
  },
};
</script>


<style scoped>
.data-display {
  text-align: center;
}

.subtitle {
  color: black
}

.table-container {
  position: relative;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.data-table th, .data-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.data-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.data-table td {
  font-size: 1.2em;
}

.no-data-message {
  font-style: italic;
  color: #888;
}

.hover-box {
  position: fixed;
  display: flex;
  background-color: #800080;
  opacity: .8;
  flex-direction: column;
  pointer-events: none;
  transform: translate(0%, 0%);
  border-radius: 4px;
  white-space: normal;
  word-wrap: break-word;
  max-width: 200px; /* Adjust this value as needed */
  max-height: none;
  overflow: auto;
}


.hover-box p {
  color: white;
  margin: 5px;
  text-align: left;
}
</style>
