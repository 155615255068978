<template>
  <div class="data-page">
    <AppHeader />
    <main>
      <DataDisplay :ticker="selectedTicker" />
    </main>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "@/components/topHeader.vue";
import AppFooter from "@/components/mainFooter.vue";
import DataDisplay from "@/components/dataDisplay.vue";
import router from "@/router"
import eventBus from '@/eventBus';

export default {
  props: ['ticker'],
  name: "DataPage",
  components: {
    AppHeader,
    AppFooter,
    DataDisplay,
  },
  data() {
    return {
      selectedTicker: this.ticker,
    };
  },
  mounted() {
    console.log('Data Component loaded.');
    eventBus.on('tickerSelected', this.updateSelectedTicker);
  },
  beforeUnmount() {
    eventBus.off('tickerSelected', this.updateSelectedTicker);
  },
  methods: {
    navigateToDataPage() {
      // Use Vue Router to navigate to "/data" with the ticker value as a query parameter
      this.$router.push({ path: '/data', query: { ticker: this.selectedTicker } });
    },
    updateSelectedTicker(ticker) {
      console.log('Selected Ticker:', ticker);
      this.selectedTicker = ticker;
      this.navigateToDataPage();
    },
  },
};
</script>

