<template>
  <div class="container">
    <div class="header">
      <h1 class="title">Sequent</h1>
    </div>
    <div class="form-container">
      <div :class="errorClass" v-if="errorMessage" class="error-message">
        <p class="error-text">{{ errorMessage }}</p>
      </div>
      <div class="success-container" v-if="success">
  <img src="@/assets/checkmark.png" alt="Checkmark" class="success-icon">
  <p class="success-message">Thank you for signing up with Sequent. We are excited to share this journey with you!</p>
</div>

      <div class="input-container" v-if="!success">
        <input type="text" class="textbox" v-model="name" placeholder="Name" @keyup.enter="submitSignup" />
        <input type="text" class="textbox" v-model="email" placeholder="Email" @keyup.enter="submitSignup" />
        <button class="signup-button" @click="submitSignup">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      name: '',
      errorMessage: '',
      isErrorInternal: false,
      success: false,
    };
  },
  computed: {
    errorClass() {
      return {
        'error-message': true,
        'user-error': !this.isErrorInternal && this.errorMessage !== '',
        'internal-error': this.isErrorInternal && this.errorMessage !== '',
      };
    },
  },
  methods: {
    submitSignup() {
      if (!this.name || !this.email) {
        this.errorMessage = 'Please fill out all fields';
        this.isErrorInternal = false;
        return;
      }

      if (!this.isValidEmail(this.email)) {
        this.errorMessage = 'Please enter a valid email address';
        this.isErrorInternal = false;
        return;
      }

      axios
        .post('http://localhost:3000/api/signup', {
          email: this.email,
          name: this.name,
        })
        .then((response) => {
          console.log('Signup data submitted:', response.data);
          this.errorMessage = ''; // Reset error message
          this.isErrorInternal = false;
          this.success = true;
        })
        .catch((error) => {
      console.error('Error submitting signup data:', error);
      if (error.response && error.response.status === 409) {
        this.errorMessage = 'Email already exists';
        this.isErrorInternal = false;
      } else {
        this.errorMessage =
          'We are experiencing some internal issues at the moment. Please try again later.';
        this.isErrorInternal = true;
      }
    });
    },
    isValidEmail(email) {
      const emailRegex = /\S+@\S+\.\S+/;
      return emailRegex.test(email);
    },
  },
};
</script>

<style scoped>
.container {
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  height: 100px; /* Fixed height for the header container */
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: white;
  margin-bottom: 0; 
}

.form-container {
  flex-direction: column;
  align-items: center;
  margin: 2rem; 
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textbox {
  padding: 0.6rem;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.signup-button {
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.error-message {
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
}

.user-error {
  background-color: #ff5252;
}

.internal-error {
  background-color: orange;
}

.error-text {
  font-size: 1rem;
}

.success-container {
  display: flex;
  align-items: center;
}

.success-icon {
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  margin-right: 10px; /* Adjust the margin as needed */
}

.success-message {
  color: white;
  font-size: 1rem;
}
</style>
