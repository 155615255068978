<template>
  <router-view/>
</template>

<style>
html, body {
    margin: 0%;
    padding: 0%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.title {
  font-size: 5rem;
  font-weight: bolder;
}

.subtitle {
    font-size: 1.5rem;
    font-weight: lighter;
}

.signup-button {
  padding: 0.6rem;
  background-color: #800080;
  color: #fff;
  font-size: 1rem;
  border: none;
  cursor: pointer;
}

input::placeholder{
    opacity:0.5;
}

</style>
