<template>
  <div class="navbar">
    <nav :class="{ 'nav-scroll': isScrolled }">
      <div class="nav-left">
        <h1 class="logo">Sequent</h1>
        <ul class="nav-links">
          <li><a href="#">About Us</a></li>
          <li><a href="#">Product</a></li>
        </ul>
      </div>
      <div class="nav-right">
        <div class="search-bar">
          <Searchbar />
          <button @click="search">Search</button>
        </div>
      </div>
    </nav>
    <div class="content">
      <!-- Rest of your page content -->
    </div>
  </div>
</template>

<script>
import Searchbar from './searchbar.vue';

export default {
  name: 'header',
  components: {
    Searchbar
  },
  data() {
    return {
      searchText: '',
      isScrolled: false
    };
  },
  mounted() {
    const options = {
      rootMargin: '0px',
      threshold: 0.1
    };

    this.observer = new IntersectionObserver(this.handleIntersect, options);
    this.observer.observe(this.$el);
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.unobserve(this.$el);
    }
  },
  methods: {
    handleIntersect(entries) {
      const entry = entries[0];
      this.isScrolled = !entry.isIntersecting;
    },
    search() {
      // Perform search action
      console.log('Searching for:', this.searchText);
    }
  }
};
</script>

<style>
nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  z-index: 9999; /* Ensure navbar is above other content */
}

.nav-scroll {
  background-color: white;
}

.nav-left {
  display: flex;
  align-items: center;
  flex: 1;
}

.logo {
  font-size: 24px;
  margin-right: 20px;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-right: 15px;
}

.nav-links a {
  text-decoration: none;
  color: #555555;
}

.nav-right {
  display: flex;
  align-items: center;
}

.search-bar {
  display: flex;
}

.search-bar input {
  width: 200px;
  padding: 5px;
  border: 1px solid #800080;
  outline: none;
}

.search-bar button {
  padding: 5px 10px;
  background-color: #800080;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.navbar {
  margin-bottom: 80px;
}

.nav-links a {
  text-decoration: none;
  color: #555555;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #800080;
}

</style>
