<template>
  <footer class="footer">
    <p class="footer-text">© 2023 Artificial Intelligence Inc. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: "mainFooter",
};
</script>

<style scoped>
.footer {
  background-color: #333;
  padding: 20px;
  color: #fff;
  text-align: center;
}

.footer-text {
  margin: 0;
  font-size: 0.8em;
}
</style>
