<template>
  <div class="login-page">
    <header>
      <h1 class="title">Sequent</h1>
      <h1 class="subtitle">Login</h1>
    </header>
    <div class="login-form">
      <input type="text" v-model="username" placeholder="Username" />
      <input type="password" v-model="password" placeholder="Password" />
      <button class="signup-button" @click="submitForm">Submit</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    submitForm() {
      // Perform your login logic here
      console.log('Submitted');
    },
  },
};
</script>

<style scoped>
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
}


.title {
    color: black;
    margin: 0px 00px 20px 0px;
}


.subtitle {
  font-size: 2rem;
  color: black;
  font-weight: lighter;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

input {
  width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #006400;
}

.signup-button {
  border-radius: 10%;
}

.submit-button:hover {
  background-color: #004f00;
}
</style>