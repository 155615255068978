<template>
  <div class="search-bar">
    <input
      ref="input"
      type="text"
      v-model="searchText"
      placeholder="Ticker, Company, etc.."
      @input="filterResults"
    />
    <ul v-if="showDropdown" class="dropdown" :style="dropdownStyle">
      <li v-for="(result, index) in displayedResults" :key="result.Ticker" :class="{ 'highlighted': index === hoveredIndex }">
        <router-link :to="{ name: 'data', query: { ticker: result.Ticker } }" @click="selectTicker(result.Ticker)">
          <div class="ticker-item">
            <div class="ticker-text">{{ result.Ticker }}</div>
          </div>
        </router-link>
      </li>
      <li v-if="hasMoreItems">
        <button @click="loadMoreItems">More items...</button>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import eventBus from '@/eventBus';

export default {
  data() {
    return {
      searchText: '',
      allResults: [], // All data loaded from the API
      filteredResults: [], // Filtered results based on search text
      displayedResults: [], // Results to be displayed in the dropdown
      showDropdown: false,
      limit: 4, // Maximum number of items initially displayed in the dropdown
      currentIndex: 0, // Current index for displaying items
      hoveredIndex: -1, // Currently hovered item index
    };
  },
  mounted() {
    axios
      .get('http://localhost:3000/api/company_tickers')
      .then(response => {
        this.allResults = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    filterResults() {
      if (this.searchText.trim() === '') {
        this.filteredResults = [];
        this.showDropdown = false;
        return;
      }

      this.filteredResults = this.allResults.filter(result =>
        result.Ticker.toLowerCase().startsWith(this.searchText.toLowerCase()) ||
        result.Company.toLowerCase().startsWith(this.searchText.toLowerCase())
      );
      this.currentIndex = 0;
      this.updateDisplayedResults();
      this.showDropdown = true;
    },
    updateDisplayedResults() {
      this.displayedResults = this.filteredResults.slice(0, this.currentIndex + this.limit);
    },
    loadMoreItems() {
      this.currentIndex += this.limit;
      this.updateDisplayedResults();
    },
    selectTicker(ticker) {
      eventBus.emit('tickerSelected', ticker);
    },
  },
  computed: {
    hasMoreItems() {
      return this.currentIndex + this.limit < this.filteredResults.length;
    },
    dropdownStyle() {
      const inputWidth = this.$refs.input.offsetWidth;
      return {
        width: `${inputWidth}px`,
      };
    },
  },
};
</script>

<style scoped>
.search-bar {
  position: relative;
  display: inline-block;
}

.search-bar input {
  width: 200px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.search-bar input:focus {
  border-color: #800080;
}

.dropdown {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  list-style-type: none;
  padding: 0;
  margin-top: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
}

.dropdown li {
  padding: 8px;
  cursor: pointer;
  text-align: left;
  
}

.dropdown li:hover {
  background-color: rgba(255, 164, 255, 0.296);
}

.dropdown button {
    border: none;
    box-shadow: none;
    font-weight: bold;
    background: none;
    padding: 0;
}

.ticker-item {
  display: flex;
  align-items: center;
}

.ticker-text {
  margin-left: 10px;
}

</style>
