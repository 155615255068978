import { createRouter, createWebHistory } from 'vue-router'
import loginView from '@/views/loginView.vue'
import dataView from '@/views/dataView.vue'
import waitListView from '@/views/waitListView.vue'
import searchbar from '@/components/searchbar.vue'

const routes = [
  {
    path: '/',
    name: 'waitlist',
    component: waitListView
  },
  {
    path: '/login',
    name: 'login',
    component: loginView
  },
  {
    path: '/data',
    name: 'data',
    component: dataView,
    props: (route) => ({
      ticker: route.query.ticker,
    }),
  },
  {
    path: '/staging',
    name: 'staging',
    component: searchbar
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
